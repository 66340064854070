//--------------------------------------------------------------
// GOOGLE RECAPTCHA
//--------------------------------------------------------------

const siteKey = '6LdGK7sUAAAAAFeq0-sKiG7gIJK50PSEzd-wH81L';

const captchaField = document.querySelector('#g-recaptcha-response');

if (captchaField) {
	grecaptcha.ready(function() {
		grecaptcha.execute(`${siteKey}`, { action: 'homepage' }).then(function(token) {
			captchaField.value = token;
		});
	});
}
