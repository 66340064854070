//--------------------------------------------------------------
// GOOGLE MAPS
//--------------------------------------------------------------

// Initialize Map
window.initMap = _ => {
	const mapContainer = document.querySelector('#google-map');
	const mapContact = document.querySelector('#google-map-contact-1');

	// Bail Early.
	if (!mapContainer && !mapContact) {
		return false;
	}

	const mapZoom = 8;
	let map;

	// Home Page
	if (mapContainer) {
		const markers = [...document.querySelectorAll('[data-js-marker]')];

		// Create Map.
		map = new google.maps.Map(mapContainer, {
			center: new google.maps.LatLng(0, 0),
			zoom: mapZoom,
		});

		markers.forEach(marker => setMarker(marker, map));

		centerMap(map, markers);
	}

	// Contact Page
	if (mapContact) {
		const contactMarkers = [...document.querySelectorAll('[data-js-contact-marker]')];
		const contactMapZoom = 14;

		contactMarkers.forEach((contactMarker, index) => {
			let contactMap = new google.maps.Map(document.querySelector(`#google-map-contact-${index + 1}`), {
				center: new google.maps.LatLng(0, 0),
				zoom: contactMapZoom,
				disableDefaultUI: true,
			});

			setMarker(contactMarker, contactMap);

			singleLocationCenter(contactMap, contactMarker, contactMapZoom);

			// Trigger Resize
			const contactLocations = document.querySelector('[data-js-contact-locations]');

			function resizeMap(e) {
				if (!e.target.matches('summary')) {
					return;
				}

				google.maps.event.trigger(contactMap, 'resize');

				singleLocationCenter(contactMap, contactMarker, contactMapZoom);
			}

			if (contactLocations) {
				contactLocations.addEventListener('click', resizeMap);
			}
		});
	}

	// Set Map Markers.
	function setMarker(marker, map) {
		const position = { lat: Number(marker.dataset.lat), lng: Number(marker.dataset.lng) };

		let newMarker = new google.maps.Marker({
			position: position,
			map: map,
			title: marker.dataset.name,
		});

		newMarker.setMap(map);
	}

	function singleLocationCenter(map, marker, zoom) {
		let latlng = new google.maps.LatLng(Number(marker.dataset.lat), Number(marker.dataset.lng));
		map.setCenter(latlng);
		map.setZoom(zoom);
	}

	// Center Map.
	function centerMap(map, markers) {
		let bounds = new google.maps.LatLngBounds();

		// Create bounds.
		markers.forEach(marker => {
			let latlng = new google.maps.LatLng(Number(marker.dataset.lat), Number(marker.dataset.lng));

			bounds.extend(latlng);
		});

		map.fitBounds(bounds);
	}
};

//-----------------------------------------
// Open Contact Location
//-----------------------------------------
const locationHash = window.location.hash;

// If this link is from the location home block to contact page.
if (locationHash) {
	const location = document.querySelector(locationHash);

	if (location) {
		location.setAttribute('open', '');
	}
}
