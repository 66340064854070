//--------------------------------------------------------------
// SLICK SLIDER
// https://kenwheeler.github.io/slick/
//--------------------------------------------------------------

import 'slick-carousel';

$('.slick-slider').slick({
	dots: true,
	infinite: true,
	autoplay: true,
	speed: 1000,
	autoplaySpeed: 4000,
	slidesToShow: 1,
	slidesToScroll: 1,
	pauseOnHover: false,
	fade: true,
	arrows: false,
});

//-----------------------------------------
// Customer Reviews
//-----------------------------------------
$('.customer-reviews').slick({
	dots: false,
	infinite: true,
	autoplay: false,
	speed: 1000,
	autoplaySpeed: 2000,
	slidesToShow: 1,
	slidesToScroll: 1,
	pauseOnHover: false,
	fade: false,
	arrows: true,
});

// Filter slider buttons.
const isTextNode = (_, el) => el.nodeType === Node.TEXT_NODE;

$('.customer-reviews .slick-prev')
	.append('<span class="fas fa-arrow-left"></span>')
	.addClass('button button--round')
	.contents()
	.filter(isTextNode)
	.remove();
$('.customer-reviews .slick-next')
	.append('<span class="fas fa-arrow-right"></span>')
	.addClass('button button--round')
	.contents()
	.filter(isTextNode)
	.remove();
