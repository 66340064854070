//--------------------------------------------------------------
// PRODUCTS
//--------------------------------------------------------------

//-----------------------------------------
// Open Accordion Product
//-----------------------------------------
const productHash = window.location.hash;

// If we have a hash.
if (productHash) {
	const product = document.querySelector(productHash);

	if (product) {
		product.setAttribute('open', '');
	}
}
